.cards__first__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 10px 280px;
}

.cards__second__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 10px 280px;
}

.lg__card {
  border-radius: 10px;
  box-shadow: 5px 10px 18px #cbdce4;
  font-family: 'Newsreader', serif;
  // font-family: 'Lora', serif;
  text-align: left;

  .card__media {
    width: 100%;
    height: 310px;
    border-radius: 10px 10px 0 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 310px;
      border-radius: 10px 10px 0 0;
    }

    iframe {
      width: 100%;
      height: 310px;
      border-radius: 10px 10px 0 0;
    }
  }

  .card__info {
    position: relative;
    padding: 20px;

    .card__title {
      margin-bottom: 20px;
      font-size: 1.2rem;
      font-weight: 500;
      min-height: 110px;
    }
    .fixed__container {
      position: absolute;
      bottom: 15px;

      .card__update__info {
        margin-bottom: 10px;
        font-size: 0.8rem;

        span {
          margin-left: 5px;
        }
      }

      .card__link {
        font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji', 'Segoe UI Symbol';
        background-color: $red-btn;
        color: $light-color;
        border-radius: 3px;
        padding: 8px;
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: none;

        button {
          outline: 0;
          border: none;
          background: transparent;
          color: #fff;
          font-size: 0.9rem;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }
}

.xs__card {
  border-radius: 10px;
  box-shadow: 5px 10px 18px #cbdce4;
  font-family: 'Newsreader', serif;
  // font-family: 'Lora', serif;
  text-align: left;

  .card__media {
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 200px;
      border-radius: 10px 10px 0 0;
    }

    iframe {
      width: 100%;
      height: 200px;
      border-radius: 10px 10px 0 0;
    }
  }

  .card__info {
    position: relative;
    padding: 10px 20px;

    .card__title {
      margin-bottom: 20px;
      font-size: 1.2rem;
      font-weight: 500;
      min-height: 180px;
    }

    .fixed__container {
      position: absolute;
      bottom: 15px;

      .card__update__info {
        margin-bottom: 10px;
        font-size: 0.8rem;

        span {
          margin-left: 5px;
        }
      }

      .card__link {
        font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji', 'Segoe UI Symbol';
        background-color: $red-btn;
        color: $light-color;
        border-radius: 3px;
        padding: 8px;
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: none;

        button {
          outline: 0;
          border: none;
          background: transparent;
          color: #fff;
          font-size: 0.9rem;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 349px) {
  .cards__first__row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .cards__second__row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .lg__card {
    .card__media {
      height: 220px;

      img {
        height: 220px;
      }

      iframe {
        height: 220px;
      }
    }

    .card__info {
      padding: 10px 15px;

      .card__title {
        margin-bottom: 50px;
        // min-height: 110px;
      }
    }
  }

  .xs__card {
    .card__media {
      height: 220px;

      img {
        height: 220px;
      }

      iframe {
        height: 220px;
      }
    }

    .card__info {
      padding: 10px 15px;

      .card__title {
        margin-bottom: 50px;
        // min-height: 110px;
      }
    }
  }
}

@media screen and (min-width: 349px) and (max-width: 412px) {
  .cards__first__row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .cards__second__row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .lg__card {
    .card__media {
      height: 220px;

      img {
        height: 220px;
      }

      iframe {
        height: 220px;
      }
    }

    .card__info {
      padding: 10px 15px;

      .card__title {
        margin-bottom: 20px;
        // min-height: 110px;
      }
    }
  }

  .xs__card {
    .card__media {
      height: 220px;

      img {
        height: 220px;
      }

      iframe {
        height: 220px;
      }
    }

    .card__info {
      padding: 10px 15px;

      .card__title {
        margin-bottom: 20px;
        // min-height: 110px;
      }
    }
  }
}

@media screen and (min-width: 412px) and (max-width: 509px) {
  .cards__first__row {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }

  .cards__second__row {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }

  .lg__card {
    .card__media {
      height: 220px;

      img {
        height: 220px;
      }

      iframe {
        height: 220px;
      }
    }

    .card__info {
      padding: 10px 15px;

      .card__title {
        margin-bottom: 20px;
        // min-height: 110px;
      }
    }
  }

  .xs__card {
    .card__media {
      height: 220px;

      img {
        height: 220px;
      }

      iframe {
        height: 220px;
      }
    }

    .card__info {
      padding: 10px 15px;

      .card__title {
        margin-bottom: 20px;
        // min-height: 110px;
      }
    }
  }
}

@media screen and (min-width: 509px) and (max-width: 567px) {
}

@media screen and (min-width: 567px) and (max-width: 652px) {
}

@media screen and (min-width: 652px) and (max-width: 891px) {
}

@media screen and (min-width: 891px) and (max-width: 1100px) {
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1284px) {
}

@media screen and (min-width: 1284px) and (max-width: 1500px) {
}
