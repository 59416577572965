#footer {
  display: flex;
  justify-content: space-between;
  background-color: $dark-blue;
  padding: 30px 150px;
  font-family: 'Newsreader', sans-serif;
  gap: 50px;

  &__map {
    display: flex;
    gap: 60px;
    width: 60%;

    .footer__link {
      color: $light-color;
      text-decoration: underline;
      font-size: 1.1rem;
      font-weight: 500;
      transition: color 0.2s ease-in;

      &:hover {
        color: $logo-green;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    width: 40%;

    &__title {
      color: $light-color;
      font-size: 1.4rem;
      font-weight: 500;

      span {
        color: $logo-green;
        padding-right: 4px;
      }
    }

    a {
      color: $light-color;
      text-decoration: underline;
      font-size: 1.1rem;

      span {
        i {
          margin-right: 5px;
        }
      }
    }

    span {
      font-size: 1.1rem;
      p {
        margin-top: 5px;
        color: $light-color;

        span {
          i {
            margin-right: 5px;
            color: $light-color;
          }
        }

        a {
          color: $logo-green;
        }

        span {
          padding-left: 3px;
        }
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 349px) {
  #footer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    font-family: 'Newsreader', sans-serif;
    gap: 0;

    &__map {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      padding-bottom: 30px;

      .footer__link {
        font-size: 0.95rem;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

@media screen and (min-width: 349px) and (max-width: 412px) {
  #footer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    font-family: 'Newsreader', sans-serif;
    gap: 0;

    &__map {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      padding-bottom: 30px;

      .footer__link {
        font-size: 0.95rem;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

@media screen and (min-width: 412px) and (max-width: 509px) {
  #footer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    font-family: 'Newsreader', sans-serif;
    gap: 0;

    &__map {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      padding-bottom: 30px;

      .footer__link {
        font-size: 0.95rem;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

@media screen and (min-width: 509px) and (max-width: 567px) {
}

@media screen and (min-width: 567px) and (max-width: 652px) {
}

@media screen and (min-width: 652px) and (max-width: 891px) {
}

@media screen and (min-width: 891px) and (max-width: 1100px) {
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1284px) {
}

@media screen and (min-width: 1284px) and (max-width: 1500px) {
}
