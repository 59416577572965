.header {
  position: relative;
  width: 100%;
  height: 68vh;
  color: $light-color;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &__members {
    background-image: linear-gradient(to bottom, #ffffff33, #00000033),
      $members-header;
  }

  &__research {
    background-image: linear-gradient(to bottom, #ffffff33, #00000033),
      $research-header;
  }

  &__blogs {
    background-image: linear-gradient(to bottom, #ffffff33, #00000033),
      $blogs-header;
  }
}

// Media Queries
@media screen and (max-width: 349px) {
  .header {
    height: 30vh;

    .page__header__title {
      font-size: 1.8rem;
      left: 40px;
      bottom: 20px;
    }
  }
}

@media screen and (min-width: 349px) and (max-width: 412px) {
  .header {
    height: 30vh;

    .page__header__title {
      font-size: 1.8rem;
      left: 40px;
      bottom: 20px;
    }
  }
}

@media screen and (min-width: 412px) and (max-width: 509px) {
  .header {
    height: 40vh;

    .page__header__title {
      font-size: 2rem;
      left: 60px;
      bottom: 20px;
    }
  }
}

@media screen and (min-width: 509px) and (max-width: 567px) {
}

@media screen and (min-width: 567px) and (max-width: 652px) {
}

@media screen and (min-width: 652px) and (max-width: 891px) {
}

@media screen and (min-width: 891px) and (max-width: 1100px) {
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1284px) {
}

@media screen and (min-width: 1284px) and (max-width: 1500px) {
}
