/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem;
  background-color: #fff;
  z-index: 3;
}

.nav-header {
  display: flex;
  justify-content: space-between;
}
#nav-mcgill {
  width: 200px;
  height: 65px;
  transition: 0.2s;
  padding-right: 20px;
  margin-right: 5px;
  border-right: 2px solid gray;
}
#nav-logo {
  width: 340px;
  height: 35px;
  transition: 0.2s;
  margin-right: 7rem;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: gray;
}
.nav-btn .nav-icon {
  font-size: 1.5rem;
}

.nav-links {
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  list-style-type: none;
}
.nav-item {
  display: block;
  text-decoration: none;
  width: 150px;
  padding: 0.5rem 0;
  margin: 0 1rem;
  text-align: center;
  font-size: 1.08rem;
  font-weight: 600;
  color: #495057;
  font-family: 'Lora', serif;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.nav-item:hover {
  color: #fff;
  background-color: #3cafa2;
  border-radius: 3px;
  text-decoration: none;
}

.show-nav {
  height: 35vh;
}
// Media Queries
@media screen and (max-width: 349px) {
  .navbar {
    padding: 0.75rem;
  }

  #nav-mcgill {
    width: 120px;
    height: 39px;
    padding-right: 8px;
  }
  #nav-logo {
    width: 170px;
    height: 17.5px;
    margin-right: 0;
  }

  .nav-btn .nav-icon {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 349px) and (max-width: 412px) {
  .navbar {
    padding: 0.75rem;
  }

  #nav-mcgill {
    width: 120px;
    height: 39px;
    padding-right: 8px;
  }
  #nav-logo {
    width: 180px;
    height: 20px;
    margin-right: 3rem;
  }

  .nav-btn .nav-icon {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 412px) and (max-width: 509px) {
  .navbar {
    padding: 2rem 1.5rem 0.75rem;
  }

  #nav-mcgill {
    width: 140px;
    height: 48px;
    padding-right: 8px;
  }
  #nav-logo {
    width: 230px;
    height: 25px;
    margin-right: 3rem;
  }

  .nav-btn .nav-icon {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 509px) and (max-width: 567px) {
}

@media screen and (min-width: 567px) and (max-width: 652px) {
}

@media screen and (min-width: 652px) and (max-width: 891px) {
}

@media screen and (min-width: 891px) and (max-width: 1100px) {
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1284px) {
}

@media screen and (min-width: 1284px) and (max-width: 1500px) {
}

@media screen and (min-width: 1100px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    margin: 0.5rem auto;
    padding-top: 0.5rem;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 30px;
  }
}

// /* Navbar */
// .navbar {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 105px;
//   padding: 0.75rem 2.6rem;
//   background-color: $light-color;
//   z-index: 4;

//   &__logo {
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;

//     .university__logo {
//       border-right: 2px solid gray;

//       a {
//         width: 175px;
//         height: 65px;
//         transition: 0.2s;
//         padding-right: 20px;

//         img {
//           width: 175px;
//           height: 65px;
//         }
//       }
//     }

//     .lab__logo {
//       margin-left: 5px;

//       a {
//         width: 340px;
//         height: 35px;
//         transition: 0.2s;
//         margin-right: 7rem;

//         img {
//           width: 340px;
//           height: 35px;
//         }
//       }
//     }
//   }

//   .list__icon {
//     display: none;
//   }

//   &__list {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     font-family: 'Lora', serif;
//     text-transform: uppercase;
//     font-size: 1rem;
//     font-weight: 600;
//     padding: 0;
//     margin: 0;

//     &__item {
//       display: block;
//       text-align: center;
//       margin: 0 1rem;
//       padding: 0.5rem 0;
//       width: 150px;
//       color: inherit;
//       transition: all 0.2s ease;

//       li {
//         text-decoration: none;
//       }

//       &:hover {
//         background-color: $logo-green;
//         color: $light-color;
//         text-decoration: none;
//         border-radius: 2px;
//       }
//     }
//   }
// }
