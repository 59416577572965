#home {
  overflow: hidden;
  width: 100%;
  margin-top: 105px;
  text-align: center;

  #landing {
    display: block;
    position: relative;
    width: 100vw;
    height: 90vh;
    object-fit: cover;
  }

  #banner {
    position: absolute;
    text-transform: uppercase;
    top: 60%;
    left: 5%;
    transform: translate(0%, -50%);
    color: #fff;
    text-shadow: 2px 2px 4px #000000;
    line-height: 3.8rem;
    font-size: 1.2rem;

    h2 {
      margin-left: 15px;
    }

    h1 {
      padding: 30px 0;
      margin: 10px 15px 50px;
      font-weight: 850;
    }

    .landing-btn {
      display: block;
      border: 3px solid #3cafa2;
      border-radius: 4px;
      transition: all 0.2s ease;
      width: 250px;
      color: #fff;
      justify-self: center;
      text-decoration: none;
      left: 50%;
      margin: auto;
      margin-top: 12px;

      &:hover {
        background-color: #fa1717;
        color: #fff;
        border: 3px solid #fa1717;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .first {
      background-color: #3cafa2;
    }
    .second {
      background: transparent;
    }
  }

  &__challenge {
    padding: 50px 250px;
    background-color: $light-blue;
    // background-color: rgba($color: $facebook-blue, $alpha: 0.3);
    color: $normal-text;

    h2 {
      text-align: left;
    }

    p {
      margin: 0;
    }
  }

  &__mission {
    display: flex;

    &__content {
      padding: 60px;

      .mission__link {
        outline: none;
        border: 0;
        border-radius: 3px;
        background-color: #fa1717;
        color: #fff;
        font-family: 'Newsreader', serif;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1rem;
        transition: all 0.2s ease-in;
      }
    }

    &__bg {
      width: 720px;
      height: 520px;
      z-index: 1;

      img {
        padding-right: 20px;
        object-fit: contain;
        width: 720px;
        height: 520px;
      }
    }
  }

  &__latest {
    padding: 1rem 0;

    .latest__link {
      margin: 1rem 0;

      a {
        font-size: 1.45rem;
        font-weight: 500;
        line-height: 1.2rem;
        color: $red-btn;
        transition: all 0.2s ease-in;

        span {
          margin-left: 0.5rem;
        }

        &:hover {
          text-decoration: none;
          letter-spacing: 2px;
          color: #d61616;
        }
      }
    }
  }

  &__contact {
    .contact__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background-color: $light-blue;
      font-family: 'Newsreader', sans-serif;

      &__info {
        padding: 25px 50px;

        .align__left {
          text-align: left;
          padding: 0 40px;
        }
      }

      &__form {
        padding: 25px 50px;
        background-color: rgba($color: #333, $alpha: 0.1);
      }
    }
  }

  &__footer {
    width: 100%;
    text-align: left;
    font-family: 'Newsreader', sans-serif;
    color: $light-color;
    background-color: $dark-blue;
    padding: 5px 85px;

    p {
      margin: 0;

      a {
        margin-left: 10px;
        color: $light-color;
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 349px) {
  #home {
    #banner {
      top: 25%;
      left: 50%;
      transform: translateX(-50%);

      h2 {
        font-size: 1.4rem;
        margin: 0;
      }

      h1 {
        font-size: 1.75rem;
        margin: 0;
      }

      .landing-btn {
        width: 200px;
        font-size: 1rem;
      }
    }

    &__challenge {
      padding: 40px;

      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    &__mission {
      &__content {
        padding: 40px;
        font-size: 1.2rem;

        h2 {
          font-size: 1.8rem;
        }

        p {
          font-size: 1.2rem;
        }
      }

      &__bg {
        display: none;
      }
    }

    &__latest {
      padding: 25px;

      h2 {
        font-size: 1.8rem;
      }

      .latest__link {
        a {
          font-size: 1.25rem;
        }
      }
    }

    &__contact {
      .contact__container {
        display: flex;
        flex-direction: column;

        &__info {
          padding: 40px;
          text-align: left;

          h3 {
            font-size: 1.6rem;
            margin: 0;
          }

          h5 {
            font-size: 1rem;
          }

          .align__left {
            padding: 0;
          }
        }

        &__form {
          padding: 40px 20px;

          h3 {
            font-size: 1.6rem;
          }
        }
      }
    }

    &__footer {
      padding: 5px 40px;
    }
  }
}

@media screen and (min-width: 349px) and (max-width: 412px) {
  #home {
    #banner {
      top: 23%;
      left: 50%;
      transform: translateX(-50%);

      h2 {
        font-size: 1.4rem;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        margin: 0;
      }

      .landing-btn {
        width: 200px;
        font-size: 1rem;
      }
    }

    &__challenge {
      padding: 40px;

      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    &__mission {
      &__content {
        padding: 40px;
        font-size: 1.2rem;

        h2 {
          font-size: 1.8rem;
        }

        p {
          font-size: 1.2rem;
        }
      }

      &__bg {
        display: none;
      }
    }

    &__latest {
      padding: 25px;

      h2 {
        font-size: 1.8rem;
      }

      .latest__link {
        a {
          font-size: 1.25rem;
        }
      }
    }

    &__contact {
      .contact__container {
        display: flex;
        flex-direction: column;

        &__info {
          padding: 40px;
          text-align: left;

          h3 {
            font-size: 1.6rem;
            margin: 0;
          }

          h5 {
            font-size: 1rem;
          }

          .align__left {
            padding: 0;
          }
        }

        &__form {
          padding: 40px 20px;

          h3 {
            font-size: 1.6rem;
          }
        }
      }
    }

    &__footer {
      padding: 5px 40px;
    }
  }
}

@media screen and (min-width: 412px) and (max-width: 509px) {
  #home {
    #banner {
      top: 23%;
      left: 50%;
      transform: translateX(-50%);

      h2 {
        font-size: 1.5rem;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        margin: 0;
      }

      .landing-btn {
        width: 220px;
        font-size: 1rem;
      }
    }

    &__challenge {
      padding: 40px;

      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    &__mission {
      &__content {
        padding: 40px;
        font-size: 1.2rem;

        h2 {
          font-size: 1.8rem;
        }

        p {
          font-size: 1.2rem;
        }
      }

      &__bg {
        display: none;
      }
    }

    &__latest {
      padding: 25px;

      h2 {
        font-size: 1.8rem;
      }

      .latest__link {
        a {
          font-size: 1.25rem;
        }
      }
    }

    &__contact {
      .contact__container {
        display: flex;
        flex-direction: column;

        &__info {
          padding: 40px;
          text-align: left;

          h3 {
            font-size: 1.6rem;
            margin: 0;
          }

          h5 {
            font-size: 1rem;
          }

          .align__left {
            padding: 0;
          }
        }

        &__form {
          padding: 40px 20px;

          h3 {
            font-size: 1.6rem;
          }
        }
      }
    }

    &__footer {
      padding: 5px 40px;
    }
  }
}

@media screen and (min-width: 509px) and (max-width: 567px) {
}

@media screen and (min-width: 567px) and (max-width: 652px) {
}

@media screen and (min-width: 652px) and (max-width: 891px) {
}

@media screen and (min-width: 891px) and (max-width: 1100px) {
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1284px) {
}

@media screen and (min-width: 1284px) and (max-width: 1500px) {
}
