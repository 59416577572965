// Colors
$light-blue: #cbdce4;
$normal-text: #495057;
$light-color: #fff;
$red-btn: #ed1b2f;
$dark-blue: #14213d;

$facebook-blue: #43609c;
$twitter-blue: #1da1f2;
$linkedin-blue: #0077b5;

$logo-green: #3cafa2;

// Header images
// $dashboard-bg: url('./assets/Bgs/login.jpg');
$members-header: url('./assets/Headers/members.jpg');
$research-header: url('./assets/Headers/research.jpg');
$blogs-header: url('./assets/Headers/latest.jpg');
